// /**
//  * Buttons
//  */
$btn-font-size: 14px !default;
$btn-font-size-mobile: 14px !default;

$btn-padding-x: 25px !default;
$btn-padding-y: 8px !default;

$line-height-base: 30px !default;


/* Common button styles */
.btn {
    font-family: $font-family-bold;
    font-weight: 600;
    display: inline-block;
    padding: $btn-padding-y $btn-padding-x;
    color: $color-white;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    font-size: $btn-font-size;
    line-height: $line-height-base;
    letter-spacing: .1px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    border-radius: 4px;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    outline: 0;
    border: 0 !important;
    background: none;
    position: relative;
    z-index: 1;
    @include transition;

    &:focus {
        outline: none;
    }

    > span {
        vertical-align: middle;
    }

    &:disabled {
        pointer-events: none;
    }

    &:hover {
        text-decoration: none !important;
    }

    @include media-breakpoint-down(sm) {
        font-size: 13px;
    }

    &--primary {
        color: $color-white;
        overflow: hidden;
        border: 1px solid $color-primary !important;
        padding: 10px $btn-padding-x;

        background: linear-gradient(to left, transparent 50%, $color-primary 50%);
        background-size: 210% 100%;
        background-position: left bottom;
        transition: all .3s ease-out;

        &:hover {
            background-position: right bottom;
            color: $color-primary;
        }
    }

    &--header {
        background: #BFFFDC;
        border-radius: 81px;
        padding: 10px 15px;
        min-width: 57px;

        margin-right: 15px;

        &:hover {
            background: darken($color: #BFFFDC, $amount: 20);
        }
    }

    &--buynow {
        color: $color-white;
        overflow: hidden;
        border: 1px solid $color-bg-btn-buynow !important;
        padding: 10px 20px;
        border-radius: 81px;

        background: linear-gradient(to left, transparent 50%, $color-bg-btn-buynow 50%);
        background-size: 210% 100%;
        background-position: left bottom;
        transition: all .3s ease-out;

        &:hover {
            background-position: right bottom;
            color: $color-bg-btn-buynow !important;

            svg {
                fill: $color-bg-btn-buynow;
            }
        }
    }

    &--landing {
        display: inline-flex;
        align-items: center;
        background: linear-gradient(267.62deg, #77FFFF 13.08%, #52FFA4 90.02%);
        border-radius: 81px;
        color: #004737;
        font-size: 15px;

        svg {
            margin-left: 5px;
        }

        &:hover {
            background: linear-gradient(267.62deg, #52FFA4 13.08%, #77FFFF 90.02%);
        }
    }

    &--purchase {
        color: $color-typo-darken;
        overflow: hidden;
        border: 1px solid $color-white !important;
        padding: 10px 20px;
        border-radius: 81px;

        background: linear-gradient(to left, transparent 50%, $color-white 50%);
        background-size: 210% 100%;
        background-position: left bottom;
        transition: all .3s ease-out;

        &:hover {
            background-position: right bottom;
            color: $color-white !important;

        }
    }

    &--outlined {
        color: $color-white;
        overflow: hidden;
        border: 1px solid $color-white !important;
        padding: 10px 20px;
        border-radius: 81px;

        background: linear-gradient(to left, $color-white 50%, transparent 50%);
        background-size: 210% 100%;
        background-position: left bottom;
        transition: all .3s ease-out;

        &:hover {
            background-position: right bottom;
            color: $color-typo-darken !important;
        }
    }
}