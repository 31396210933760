/**
 * Colors
 */

$color-white: #fff;
$color-black: #000;
$color-primary: #000000;
$color-bg-color: #fff;
$color-bg-color-button-dashboard: #4679EC;
$color-header-bg: #0D141C;
$color-typography-primary: #000000;



$color-bg-color-features: rgba(255, 255, 255, 0.06);
$color-bg-color-features-hover: #D18820;
$color-bg-color-accordion: #0A1119;
$color-global-headline: #fff;
$color-navigation: #fff;


$color-bg-btn-buynow: #0BBC60;
$color-nav-hover: #0BBC60;
$footer-bg: #1D1D1D;
$color-bg-secondary: #1D1D1D;
$color-typo-darken: #1D1D1D;
$color-bg-number: #00FFC3;
$color-typo-inverted: #422B2B;
$color-bg-pricing: #383838;
$color-bg-color-accordion: #1D1D1D;