.s-plans {
    background: $color-bg-pricing;
    padding: 50px 0 100px;
    position: relative;
    border-radius: 0 0 23px 23px;
    position: relative;

    @include media-breakpoint-down(md) {
        padding-bottom: 50px;
    }

    &::before {
        content: '';
        width: 100%;
        height: 200px;
        background: $color-bg-pricing;

        position: absolute;
        top: -200px;
        z-index: -1;
    }

    &::after {
        content: '';
        width: 100%;
        height: 100px;
        background-color: $color-bg-pricing;
        border-radius: 0 0 50px 50px;
        display: block;

        position: absolute;
        bottom: -50px;
        z-index: 999;
    }

    .pricing-table {
        width: 100%;

        .pricing-plan {
            border-radius: 17px;
            padding: 30px 40px;
            max-width: 480px;

            &__cta {
                margin-top: 20px;

                .btn {
                    &:first-child {
                        margin-right: 10px;
                    }
                }
            }

            &-grey {
                @include transition;
                background: $color-bg-secondary;
            }

            &-wrap {
                @include media-breakpoint-down(md) {
                    &:not(:last-child) {
                        margin-bottom: 50px;
                    }
                }

                &.is-moved {
                    margin-top: 250px;

                    @include media-breakpoint-down(md) {
                        margin-top: 100px;
                    }
                }
            }
        }

        .pricing-head {
            h4 {
                font-family: $font-family-regular;
                font-weight: 700;
                font-size: 26px;
                line-height: 34px;
                color: $color-white;

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    line-height: 30px;
                }

                span {
                    display: inline-block;
                    background: linear-gradient(96.88deg, #00FFC3 -47.35%, #2DBFA7 126.12%);
                    border-radius: 7px;
                    color: #00513E;
                    padding: 0 10px;
                    font-size: 13px;

                    position: relative;
                    top: -5px;
                    left: 5px;
                }
            }

            p {
                font-family: $font-family-regular;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: 0.015em;
                color: rgba(255, 255, 255, 0.67);

                margin: 5px 0 15px;
            }
        }

        .pricing-amount {
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, 0.5);
            font-family: $font-family-regular;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            padding: 10px 0;

            div {
                position: relative;
                top: 12px;
            }

            p {
                font-family: $font-family-bold-black;
                color: #FFFFFF;
                font-weight: 800;
                font-size: 60px;
                line-height: 70px;
                padding: 0 10px;
                top: 10px;
            }

            span {
                font-family: $font-family-regular;
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: 0.015em;
                color: $color-white;

                position: relative;
                top: -10px;
            }
        }

        .leading-loose {
            list-style: none;

            li {
                color: $color-white;
                font-family: $font-family-medium;
                font-weight: 600;
                font-size: 15px;
                line-height: 26px;
                letter-spacing: 0.015em;


                &.not-pro {
                    color: #8E8E8E;
                    text-decoration: line-through;
                }

                div {
                    display: flex;
                    align-items: center;
                    padding: 10px 0;
                }

                svg {
                    margin-right: 12px;
                }

                &:not(:last-child) {
                    &::after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        display: block;
                        background: rgba(255, 255, 255, 0.06);
                    }
                }
            }
        }
    }
}