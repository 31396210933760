$menu-color: $color-primary;
$snappy: cubic-bezier(0.694, 0.048, 0.335, 1);

.menu-container {
  cursor: pointer;

  .bars {
    width: 30px;
    height: 50px;
    position: relative;
    transition: transform 0.3s $snappy;
    // border: 1px solid white;
    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: $menu-color;
      margin-top: 7px;
      &:first-child {
        margin-top: 0;
        transform: scaleX(1);
        transition: transform 0.2s $snappy;
        transition-delay: 0.4s;
      }
      &:nth-child(3) {
        width: 65%;
        transition: width 0.2s $snappy;
        transition-delay: 0.4s;
      }
    }
    .other-bar {
      width: 2px;
      height: 0;
      background-color: $menu-color;
      position: absolute;
      top: -2px;
      left: 50%;
      margin-left: -1px;
      transition: height 0.4s $snappy;
      z-index: 10;
    }
  }
  &.is-menu-open {

    .bars {
      // transform: rotate(45deg);
      transition-delay: 0.4s;
      span {
        &:first-child {
          transform: scaleX(0);
          transition: transform 0.2s $snappy;
        }
        &:nth-child(3) {
          width: 0;
          transition: width 0.2s $snappy;
        }
      }
      .other-bar {
        display: none;
      }
    }
  }
}
