@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../assets/fonts/Inter-Regular.woff2?v=3.19") format("woff2"),
        url("../assets/fonts/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/Inter-Italic.woff2?v=3.19") format("woff2"),
      url("../assets/fonts/Inter-Italic.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../assets/fonts/Inter-SemiBold.woff2?v=3.19") format("woff2"),
        url("../assets/fonts/Inter-SemiBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/Inter-BoldItalic.woff2?v=3.19") format("woff2"),
      url("../assets/fonts/Inter-BoldItalic.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../assets/fonts/Inter-Bold.woff2?v=3.19") format("woff2"),
        url("../assets/fonts/Inter-Bold.woff?v=3.19") format("woff");
}
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("../assets/fonts/Inter-Black.woff2?v=3.19") format("woff2"),
        url("../assets/fonts/Inter-Black.woff?v=3.19") format("woff");
}
/* -------------------------------------------------------
  Variable font.
  Usage:

    html { font-family: 'Inter', sans-serif; }
    @supports (font-variation-settings: normal) {
      html { font-family: 'Inter var', sans-serif; }
    }
  */
@font-face {
    font-family: "Inter var";
    font-weight: 400 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: "Regular";
    src: url("../assets/fonts/Inter-roman.var.woff2?v=3.19") format("woff2");
}
@font-face {
    font-family: "Inter var";
    font-weight: 400 900;
    font-display: swap;
    font-style: italic;
    font-named-instance: "Italic";
    src: url("../assets/fonts/Inter-italic.var.woff2?v=3.19") format("woff2");
}

/* --------------------------------------------------------------------------
  [EXPERIMENTAL] Multi-axis, single variable font.

  Slant axis is not yet widely supported (as of February 2019) and thus this
  multi-axis single variable font is opt-in rather than the default.

  When using this, you will probably need to set font-variation-settings
  explicitly, e.g.

    * { font-variation-settings: "slnt" 0deg }
    .italic { font-variation-settings: "slnt" 10deg }

  */
@font-face {
    font-family: "Inter var experimental";
    font-weight: 400 900;
    font-display: swap;
    font-style: oblique 0deg 10deg;
    src: url("../assets/fonts/Inter.var.woff2?v=3.19") format("woff2");
}
