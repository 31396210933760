.header {
	transition: all ease-out .2s !important;

	position: fixed;
	z-index: 9999;
	width: 100%;
	padding: 20px 0;
	width: 100%;

	@include media-breakpoint-down(md) {
		padding: 25px 0;
	}

	background-color: $color-white;

	.align-right {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}