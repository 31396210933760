/**
 * Global
 *
 * !default Flag
 * When building a library, a framework, a grid system or any piece of Sass
 * that is intended to be distributed and used by external developers,
 * all configuration variables should be defined with the !default flag
 * so they can be overwritten.
 */

body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
 // ---------------------
// Some basic styling...

.step {
    .global-wrapper,
    h2 {
        animation: slide-out-blurred-right 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
        -webkit-animation: slide-out-blurred-right 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    }

    &.is-active {
        .global-wrapper,
        h2 {
            animation: slide-in-blurred-left 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
            -webkit-animation: slide-in-blurred-left 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
        }
    }
}

.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: $color-bg-color;

    svg {
        font-weight:bold;
        width: 100%;
        max-width:600px;
        height:100%;
        margin: 0 auto;
    }
}