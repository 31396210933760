.accordion {
    width: 100%;
    margin: 40px auto 0;
    list-style: none;

    @include media-breakpoint-down(md) {
       padding: 0;
    }

    li {
        background: $color-bg-color-accordion;
        border-radius: 17px;
        position: relative;
        margin-bottom: 30px;

        &.active {
            background: $color-bg-color-accordion;
        }

        p {
            font-family: $font-family-regular;
            font-size: 14px;
            line-height: 22px;

            display: none;
            padding: 0 20px 30px;
            color: rgba(255, 255, 255, 0.65);

            a {
                color: $color-white;
                text-decoration: none;
                font-weight: bold;
            }
        }

        span {
            font-family: $font-family-regular;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #fff;
            display: inline-block;
            width: 100%;
            display: block;
            cursor: pointer;
            user-select: none;
            padding: 25px 20px 25px 60px;

            @include media-breakpoint-down(md) {
                font-size: 14px;
                line-height: 26px;
            }

            &.active {
                color: $color-white;
            }

            &::after {
                @include transition;
                content: '';
                background-image: url('/assets/img/icon-plus.svg');
                background-size: cover;
                display: block;
                width: 25px;
                height: 25px;
                // width: 8px;
                // height: 8px;
                // border-right: 2px solid $color-white;
                // border-bottom: 2px solid $color-white;
                position: absolute;
                left: 20px;
                top: 25px;
                // transform: rotate(45deg);
            }

            &.active::after {
                background-image: url('/assets/img/icon-minus.svg');
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}