.s-features {
    background: $color-white;
    border-radius: 25px;
    padding: 100px 0;
    margin: 35px;

    @include media-breakpoint-down(xl) {
        margin: 15px;
    }

    &-img {
        position: relative;
        margin-bottom: 25px;

        background: $color-bg-number;

        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 65px;
        height: 65px;


        @include media-breakpoint-down(sm) {
            margin: 0 auto 15px;
        }

        svg {
            max-height: 26px;
        }
    }


    &__list {
        margin: 150px 0 100px;
        list-style: none;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;


        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(xs) {
            grid-template-columns: repeat(1, 1fr);
            margin: 100px 0 50px;
        }

        &-item {
            @include transition;
            background: $color-bg-secondary;
            border-radius: 7px;
            padding: 115px 30px 50px;
            position: relative;

            &:hover {
                background: $color-typo-inverted;
            }
        }


        &-headline,
        &-wrapper {
            display: flex;
            align-content: center;
            flex-flow: column;
        }

        &-headline {
            font-family: $font-family-headings;
            font-size: 18px;
            line-height: 26px;
            color: $color-white;
            display: flex;
        }

        &-copy {
            font-family: $font-family-medium;
            font-size: 14px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.65);

            margin-top: 15px;
            text-align: justify;
        }

        &-number {
            font-family: $font-family-medium;
            font-weight: 600;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);

            position: absolute;
            top: 30px;
            left: 30px;
        }
    }
}