.global-wrapper {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
        justify-content: center;
    }
}

.global-section-number {
    background: $color-bg-number;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: $color-typo-darken;
        font-family: $font-family-medium;
        font-weight: 600;

        font-size: 18px;
        line-height: 26px;
    }
}

.global-section-title {
    font-family: $font-family-medium;
    font-weight: 600;
    margin-left: 15px;
    color: $color-white;

    &.inverted {
        color: $color-typo-darken;
    }
}
.global-section-headline {
    text-align: right;
    font-family: $font-family-headings;
    font-weight: 700;
    font-size: 70px;
    line-height: 80px;

    color: $color-white;
    max-width: 500px;
    float: right;

    @include media-breakpoint-down(md) {
       text-align: center;
       max-width: 100%;
       width: 100%;
       margin-top: 25px;
    }

    @include media-breakpoint-down(lg) {
        font-size: 40px;
        line-height: 50px;
    }

    span {
        font-style: italic;
    }

    &.inverted {
        color: $color-typo-inverted;
    }
}