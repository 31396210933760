.s-infra {
    padding: 100px 0;
    position: relative;


    &::after {
        content: '';
        width: 100%;
        height: 45%;
        background-color: $color-white;
        display: block;
        position: absolute;
        top: 45%;
        z-index: -1;
    }


    &__wrapper {
        background: $color-white;
        border-radius: 25px;
        padding: 100px 0;
        margin: 250px 35px 0;

        @include media-breakpoint-down(xl) {
            margin: 200px 15px 0;
        }

        @include media-breakpoint-down(md) {
            margin: 150px 15px 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 150px 15px 0;
        }

        img {
            position: relative;
            margin-top: -250px;

            @include media-breakpoint-down(lg) {
                margin-top: -200px;
            }

            @include media-breakpoint-down(md) {
                margin-top: -180px;
            }

            @include media-breakpoint-down(sm) {
                margin-top: -140px;
            }
        }
    }

    &__headline {
        font-family: $font-family-headings;
        font-weight: 700;
        font-size: 80px;
        line-height: 90px;
        margin: 100px 0 50px;

        color: $color-black;

        max-width: 980px;
        padding: 0 35px;

        @include media-breakpoint-down(xl) {
            font-size: 50px;
            line-height: 60px;
            display: block;
        }

        @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 36px;
            margin: 25px 0;
        }
    }

    &__copy {
        font-family: $font-family-medium;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.67);

        max-width: 980px;
        display: block;
        padding: 0 35px;

        @include media-breakpoint-down(xl) {
            max-width: 100%;
        }

        @include media-breakpoint-down(lg) {
            margin: 0 auto;
            font-size: 15px;
            line-height: 26px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 14px;
            line-height: 24px;
        }
    }
}