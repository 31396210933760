.s-landing {
    background-color: $color-bg-color;
    padding: 100px 35px 50px;

    @include media-breakpoint-down(xl) {
        padding: 100px 15px 50px;
    }

    @include media-breakpoint-down(md) {
        padding-top: 70px !important;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 50px !important;
    }

    img {
        // position: absolute;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .container {
        position: relative !important;
    }

    .wrapped-box {
        position: absolute;
        top: -550px;
        left: 49%;
        transform: translate(-100%, 0%);
        margin: 0 auto;

        @include media-breakpoint-only(xxl) {
            left: calc(49% + 22px);
        }


        @include media-breakpoint-only(xl) {
            left: calc(49% + 22px);
            top: -500px;
        }

        @include media-breakpoint-down(lg) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -150%);
            margin-top: 5%;
        }

        @include media-breakpoint-down(md) {
            background-image: url('/assets/img/bg_landing.jpg');
            background-size: cover;

            position: relative;
            top: 0;
            left: 0;
            margin-top: 0;
            transform: translate(0, 0);
            border-radius: 13px;
            padding: 75px 25px;
            margin-top: 15px;
        }

    }

    small {
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.55);
        margin-top: 15px;

        @include media-breakpoint-down(lg) {
            justify-content: center;
        }

        svg {
            margin-right: 5px;
        }
    }


    &__name {
        font-family: $font-family-bold;
        font-weight: 600;

        font-size: 18px;
        line-height: 26px;
        margin-bottom: 30px;
        display: block;
        margin-bottom: 10px;

        background: linear-gradient(90.26deg, #9CFF8C 5.48%, #A8EFFF 103.18%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;


        @include media-breakpoint-down(lg) {
            text-align: center;
            font-size: 15px;
            line-height: 26px;
        }
    }

    img {
        @include media-breakpoint-down(lg) {
            margin: 0 auto;
        }
    }

    &__headline {
        overflow: hidden;
        line-height: 1;
        margin: 0;

        h1 {
            font-family: $font-family-headings;
            font-weight: 700;
            font-size: 90px;
            line-height: 101px;
            margin-bottom: 20px;

            color: #FAF8E8;

            display: inline-block;
            line-height: 1;
            animation: reveal 1.5s cubic-bezier(0.77, 0, 0.175, 1) 0.5s;

            @include media-breakpoint-down(lg) {
                text-align: center;
                font-size: 50px;
                line-height: 60px;
                display: block;
            }

            span {
                font-style: italic;
            }
        }
    }

    &__subline {
        font-family: $font-family-medium;
        font-weight: 400;
        margin-bottom: 30px;
        font-size: 22px;
        line-height: 32px;
        color: rgba(255, 255, 255, 0.67);

        max-width: 680px;

        @include media-breakpoint-down(lg) {
            margin: 0 auto 50px;
            font-size: 15px;
            line-height: 26px;
            text-align: center;
        }

        @include media-breakpoint-down(sm) {
            font-size: 14px;
            line-height: 24px;
        }
    }

    &__btns {
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }

    .btn {
        @include media-breakpoint-down(lg) {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}