.footer {
    background-color: $color-white;
    padding-top: 100px;
    border: 15px solid $color-white;

    @include media-breakpoint-down(lg) {
        padding-top: 0;
    }

    @include media-breakpoint-down(md) {
        margin-top: -50px;
    }

    .btt {
        position: absolute;
        right: 25px;
        top: 50px;
        z-index: 9;

        @include media-breakpoint-down(md) {
            top: auto;
            bottom: 25px;

            svg {
                max-width: 45px;
            }
        }
    }

    canvas {
        max-width: 500px !important;
        width: 100% !important;
        height: auto !important;
        margin: 0 auto;

        @include media-breakpoint-down(lg) {
            width: 100% !important;
            max-width: 350px !important;
            height: auto !important;
            margin: 0 auto;
        }

        @include media-breakpoint-down(md) {
            // width: 100% !important;
            // max-width: 200px !important;
            // height: auto !important;
            // margin: 0 auto;
            display: none;
        }
    }

    #animatedStage {
        position: relative;
    }

	&__wrap {
        transition: all ease-out .2s !important;
        background: $footer-bg;
        border-radius: 44px;
        position: relative;
        z-index: 9999;
        width: 100%;
        padding: 25px 75px;
        width: 100%;

        @include media-breakpoint-down(md) {
            padding: 50px;
        }
    }

    &__nav {
        width: 100%;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        svg {
            margin-bottom: 50px;
            display: block;

            @include media-breakpoint-down(md) {
                margin: 0 auto 40px;
            }
        }

        a {
            font-family: $font-family-bold;
            font-weight: 600;
            font-size: 16px;
            color: #C6C6C6;

            position: relative;
            display: block;

            &::after {
                @include transition;

                content: '';
                display: block;
                border-bottom: 3px solid $color-nav-hover;
                width: 0;
                position: absolute;
                left: 0;
            }

            &:hover {
                color: $color-nav-hover;
                text-decoration: none !important;
            }


            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }

    &__copy {
        font-family: $font-family-regular;
        font-weight: 400;
        padding: 15px;
        color: $color-black;
        text-align: left;
        font-size: 14px;
        line-height: 26px;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    &__legal {
        text-align: right;
        padding: 15px;

        a {
            font-family: $font-family-regular;
            font-weight: 400;
            color: $color-black;
            font-size: 14px;
            line-height: 26px;
            margin-left: 20px;
        }

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    .animation {
        position: relative;
    }
}