.s-faq {
    background: $color-white;
    padding: 100px 0;
    position: relative;


    &__hero {
        position: absolute;
        right: 0;
        top: 400px;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        img {
            border-radius: 25px 0 0 25px;
            max-height: 650px;
        }
    }

    .accordion {
        margin-top: 150px;
    }
}