.modal {
    margin: 0 15px;

    &__close {
        @include transition($duration: .15s);
      //   background: $color-white !important;
        color: $color-white;

        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        min-width: auto !important;

        font-size: 18px;

        &::before {
          position: relative;
          left: 0px;
        }
    }

    &__container  {
        border-radius: 8px;
        margin: 15px;
        padding: 0 !important;

        color: $color-white;
        position: relative;

        max-width: 640px;

        overflow-y: scroll;
        -ms-overflow-style: none;

        @include media-breakpoint-down(sm) {
          padding: 30px !important;
        }

        .login__header__headline {
            margin-bottom: 15px;
            text-shadow: none;
            font-size: 20px;
            line-height: 26px;

        }

        .btn {
            margin-top: 30px;
        }
    }

    form {
      h3 {
        text-align: center;
        margin-bottom: 10px;
      }

      p {
        text-align: center;
        margin-bottom: 20px;
      }

      div {
        text-align: center;
      }
    }

    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
      padding: 0;
      margin: 0 !important;
      min-height: 400px;

      @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    #subscribe-result {
      margin-top: -15px;
      text-align: center;
    }
}