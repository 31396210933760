.s-differences {
    background-color: $color-bg-secondary;
    padding: 100px 0 200px;

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 100px;
        grid-template-rows: auto;
        height: auto;
        align-items: center; /* ADDED */
        list-style: none;
        margin: 150px auto 0;
        position: relative;

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 50px;
            margin-top: 100px;
        }

        &-item-f1 {
            &::after {
                content: 'vs';
                display: block;
                color: rgba(255, 255, 255, 0.5);
                font-family: $font-family-regular;
                font-weight: 400;
                font-style: italic;
                position: absolute;
                top: 50%;
                left: 50%;

                @include media-breakpoint-down(md) {
                    position: relative;
                    top: 0;
                    left: 0;
                    text-align: center;
                    padding-top: 50px;
                }
            }
        }
    }

    &__head {
        display: flex;
        align-items: center;
        color: $color-white;
        font-family: $font-family-medium;
        font-weight: 600;

        font-size: 15px;
        line-height: 22px;

        span {
            background: linear-gradient(91.8deg, #52FFA5 -3.36%, #77FFFF 33.61%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin-right: 5px;
        }

        svg {
            margin-right: 10px;
        }
    }

    &-rs {
        &:not(:last-child) {
            margin-bottom: 65px;

            @include media-breakpoint-down(md) {
                margin-bottom: 35px;
            }
        }
    }

    &__differences {
        list-style: none;
        margin: 15px 0 0 0;
        border: 1px solid #393939;
        border-radius: 14px;
        padding: 30px;

        &-item {
            color: $color-white;
            font-family: $font-family-medium;
            font-weight: 600;

            display: flex;
            align-items: center;

            margin-bottom: 8px;

            svg {
                margin-right: 12px;
            }
        }
    }
}